import classNames from 'classnames'
import React from 'react'
import Post from 'blog/components/Post'
import type { Component } from 'constants/types'
import styles from './Posts3.module.scss'

type Props = {
  count?: number
  data: {
    fields: {
      slug: string
    }
    frontmatter: {
      image: string
      title: string
    }
  }[]
} & Component

export default function Posts3({ className, count = 99, data }: Props) {
  return (
    <div className={classNames('Posts3', styles.this, styles['posts-tertiary'], className)}>
      {data.slice(0, count).map((item) => (
        <Post
          to={item.fields.slug}
          title={item.frontmatter.title}
          image={item.frontmatter.image}
          key={item.fields.slug}
          className={styles['posts-tertiary--item']}
        />
      ))}
    </div>
  )
}
