import classNames from 'classnames'
import { graphql } from 'gatsby'
import React from 'react'
import BlogMarkdown from 'blog/components/BlogMarkdown'
import Copy from 'blog/components/Copy'
import Posts3 from 'blog/components/Posts3'
import Box from 'components/Box'
import Container from 'components/Container'
import Head from 'components/Head'
import Image from 'components/Image'
import Layout from 'components/Layout'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import Title2 from 'components/Title2'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as userApiRequest from 'libs/user-api-request'
import styles from './Blog.module.scss'

type Props = {
  data: unknown
}

export default function Blog({ data }: Props) {
  useEventPageVisited('Blog post', { title: data?.mdx?.frontmatter?.title })
  const globalContext = useGlobalContext()

  React.useEffect(() => {
    if (globalContext.user?.id) {
      userApiRequest.readBlog()
    }
  }, [globalContext.user?.id])

  if (!data) return null

  const { body, frontmatter } = data.mdx
  const { audio, canonicalUrl, image, title } = frontmatter

  return (
    <Layout className="Blog">
      <div className={classNames('Blog', styles.this)}>
        <Head
          title={frontmatter.metadata?.title || title}
          canonicalUrl={canonicalUrl}
          description={frontmatter.metadata?.description}
          image={image.publicURL}
        />
        <div className={styles.content}>
          <Container size="xxlarge">
            {image && (
              <Row size="medium">
                <Image src={image} />
              </Row>
            )}
            <Text element="h1" weight="semibold" className={styles.title}>
              {title}
            </Text>
            <Copy>
              {audio && (
                <Row size="large">
                  <Box className={styles.audio}>
                    <MediaObject
                      figure={
                        <Text align="left" element="div" size="medium" weight="medium">
                          Prefer to listen?
                        </Text>
                      }
                      center
                      inline
                      reset="mobile"
                      size="small"
                      className={styles['audio--inner']}>
                      <audio src={audio} controls className={styles['audio--player']} />
                    </MediaObject>
                  </Box>
                </Row>
              )}
              {body && <BlogMarkdown>{body}</BlogMarkdown>}
            </Copy>
          </Container>
        </div>
        <footer className={styles.footer}>
          <Container size="xxlarge">
            <Title2 size="large" small="More from">
              The Bold Report
            </Title2>
            <Posts3 count={3} data={data.posts.nodes} />
          </Container>
        </footer>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        audio
        canonicalUrl
        image {
          childImageSharp {
            fluid(maxWidth: 1256, maxHeight: 729, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
        metadata {
          title
          description
        }
        title
      }
    }
    posts: allMdx(
      filter: {
        frontmatter: { hideFromLanding: { ne: true }, template: { eq: "blog" } }
        id: { ne: $id }
      }
      limit: 4
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          image {
            childImageSharp {
              fluid(maxWidth: 924, maxHeight: 536) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
        }
      }
    }
  }
`
